import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Fab,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../hooks";
import { customToast } from "../public_components/Toast/CustomToast";
import ConfirmPopup from "../public_components/confirmPopup/ConfirmPopup";
import { Scanner } from "@yudiel/react-qr-scanner";
import {
    Add,
    Delete,
    Pause,
    PlayArrow,
    RemoveCircle,
    Upload,
} from "@mui/icons-material";
import { crateCodeToPrefixNumber } from "../../utils/crateUtils";
import crateSlice from "../../store/slices/crate";

const WorkerSendCrate = () => {
    const dispatch = useAppDispatch();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [crateIds, setCrateIds] = useState<any[]>([]);
    const [isPaused, setIsPaused] = useState(false);
    const [crateNumber, setCrateNumber] = useState<string>("");
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
        useState<boolean>(false);
    const [crateToDelete, setCrateToDelete] = useState<string>("");
    const [result, setResult] = useState("");
    const [route, setRoute] = useState("");

    const handleChange = (event: SelectChangeEvent) => {
        setRoute(event.target.value);
        setCrateIds([]);
        setResult("");
        setCrateNumber("");
    };

    useEffect(() => {
        window.sessionStorage.setItem("market", "0");
        window.sessionStorage.setItem("cart", "0");
        window.sessionStorage.setItem("orders", "0");
        window.sessionStorage.setItem("profile", "0");
        setIsLoading(false);
    }, []);

    // Unmount
    useEffect(() => {
        return () => {
            setIsPaused(true);
        };
    }, []);

    useEffect(() => {
        if (result === "") {
            return;
        }
        const { number } = crateCodeToPrefixNumber(result);
        setCrateNumber(number);
    }, [result]);
    useEffect(() => {
        handleAddCrate();
    }, [crateNumber]);
    useEffect(() => {
        setIsPaused(false);
    }, [crateIds]);

    const handleScan = (data: any) => {
        if (data) {
            setResult(data[0].rawValue);
        }
    };

    function handleAddCrate(): void {
        if (
            !result ||
            !crateNumber ||
            result.trim() === "" ||
            crateNumber.trim() === ""
        ) {
            return;
        }
        const newCrate = { code: result, number: crateNumber };

        setCrateIds((prevCrateIds) => {
            // Check if the code already exists in the array
            const codeExists = prevCrateIds.some(
                (crate) => crate.code === newCrate.code
            );

            if (codeExists) {
                // If the code already exists, don't add it
                // customToast("This crate code already exists.", "info");
                return prevCrateIds;
            } else {
                // If the code doesn't exist, add the new crate
                return [...prevCrateIds, newCrate];
            }
        });
    }

    function handleRemoveCrate(): void {
        setCrateIds([]);
    }

    function handleRemoveId(id: string): void {
        setCrateToDelete(id);
        setIsDeleteConfirmationOpen(true);
    }
    function handleYesRemoveId(): void {
        setCrateIds(
            crateIds.filter((element) => element.code !== crateToDelete)
        );
        setCrateToDelete("");
        setIsDeleteConfirmationOpen(false);
    }
    async function uploadCrates() {
        setIsLoading(true);
        const uploadPayload = {
            crateList: crateIds,
            route,
        };
        if (
            uploadPayload.crateList.length !== 0 &&
            uploadPayload.route !== ""
        ) {
            const result: any = await dispatch(
                crateSlice.tagSendCrate(uploadPayload)
            );

            if (result?.payload?.isError) {
                customToast("Error Occured", "error");
                setIsLoading(false);
                return;
            }
            customToast(result?.payload?.message, "success");
            setCrateIds([]);
            setResult("");
            setCrateNumber("");
        } else {
            customToast("Select Route and Tag Crates!", "warning");
        }
        setIsLoading(false);
    }

    return (
        <Box
            sx={{
                backgroundColor: "background.paper",
                minHeight: "90vh",
                height: "100%",
            }}
            pb={10}
            pt={2}
        >
            <ConfirmPopup
                text={`Do you want to Remove ${crateToDelete} ?`}
                isOpen={isDeleteConfirmationOpen}
                handleYes={handleYesRemoveId}
                handleClose={() => setIsDeleteConfirmationOpen(false)}
            />
            <Backdrop
                sx={(theme) => ({
                    color: "#fff",
                    zIndex: theme.zIndex.drawer + 1,
                })}
                open={isLoading}
            >
                <CircularProgress color="secondary" />
            </Backdrop>
            <Typography
                textAlign={"center"}
                variant="h5"
                color={"text.primary"}
            >
                Send Crates
            </Typography>

            {isLoading ? (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        m: 3,
                    }}
                ></Box>
            ) : (
                <>
                    <Grid
                        container
                        justifyContent={"center"}
                        alignItems={"flex-start"}
                        sx={{
                            p: 2,
                        }}
                        spacing={1}
                    >
                        <FormControl
                            sx={{ m: 1, minWidth: 120 }}
                            size="small"
                            fullWidth
                        >
                            <InputLabel id="demo-select-small-label">
                                Location
                            </InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={route}
                                label="route"
                                onChange={handleChange}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={"UPCOUNTRY"}>
                                    UPCOUNTRY
                                </MenuItem>
                                <MenuItem value={"LOWCOUNTRY"}>
                                    LOWCOUNTRY
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {route !== "" ? (
                        <>
                            <Grid
                                container
                                justifyContent={"center"}
                                alignItems={"flex-start"}
                                sx={{
                                    p: 2,
                                }}
                                spacing={1}
                            >
                                <Typography
                                    textAlign={"center"}
                                    variant="body2"
                                    color={"text.primary"}
                                >
                                    Count: {crateIds?.length}
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                justifyContent={"center"}
                                alignItems={"flex-start"}
                                sx={{
                                    p: 2,
                                }}
                                spacing={1}
                            >
                                <Grid item xs={6}>
                                    <Scanner
                                        onScan={handleScan}
                                        allowMultiple
                                        paused={isPaused}
                                        children={
                                            <Grid container spacing={3}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    textAlign={"center"}
                                                >
                                                    <Fab
                                                        onClick={() =>
                                                            setIsPaused(
                                                                !isPaused
                                                            )
                                                        }
                                                        sx={{ marginBottom: 2 }}
                                                    >
                                                        {isPaused ? (
                                                            <PlayArrow />
                                                        ) : (
                                                            <Pause />
                                                        )}
                                                    </Fab>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {result && (
                                                        <Typography
                                                            variant="caption"
                                                            color="secondary"
                                                        >
                                                            Scanned Result:{" "}
                                                            {result}
                                                        </Typography>
                                                    )}
                                                </Grid>

                                                <Grid
                                                    container
                                                    item
                                                    xs={12}
                                                    spacing={0.5}
                                                >
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            textAlign={"left"}
                                                            color={"secondary"}
                                                        >
                                                            Crate Number:{" "}
                                                            {crateNumber}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Button
                                                            onClick={
                                                                handleAddCrate
                                                            }
                                                            variant="contained"
                                                            color="secondary"
                                                            fullWidth
                                                        >
                                                            <Add />
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Button
                                                            onClick={
                                                                handleRemoveCrate
                                                            }
                                                            variant="contained"
                                                            color="error"
                                                            fullWidth
                                                        >
                                                            <Delete />
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button
                                                            onClick={
                                                                uploadCrates
                                                            }
                                                            variant="contained"
                                                            color="success"
                                                            fullWidth
                                                        >
                                                            <Upload />
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    overflow={"scroll"}
                                    container
                                    height={400}
                                    direction="column"
                                    border={1}
                                >
                                    {crateIds.map((value: any) => {
                                        return (
                                            <Grid
                                                item
                                                key={value.code}
                                                container
                                                alignContent={"center"}
                                                justifyContent={"center"}
                                                alignItems={"center"}
                                            >
                                                <Grid item xs={3}>
                                                    <IconButton
                                                        onClick={() =>
                                                            handleRemoveId(
                                                                value.code
                                                            )
                                                        }
                                                        size="small"
                                                        sx={{
                                                            borderRadius: 100,
                                                            height: 10,
                                                        }}
                                                        color="warning"
                                                    >
                                                        <RemoveCircle color="error" />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Typography
                                                        variant="caption"
                                                        color={"secondary"}
                                                    >
                                                        {value.code}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <Grid container justifyContent={"center"}>
                            <Grid>
                                <Typography variant="h5" color="secondary">
                                    Select Location !
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </>
            )}
        </Box>
    );
};

export default WorkerSendCrate;
