import {
    Alert,
    Badge,
    BadgeProps,
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    CircularProgress,
    Grid,
    InputAdornment,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    styled,
} from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import itemSlice from "../../store/slices/item";
import { customToast } from "../public_components/Toast/CustomToast";
import orderSlice from "../../store/slices/order";
import ConfirmPopup from "../public_components/confirmPopup/ConfirmPopup";
import { ItemState } from "../../utils/itemUtils";
import { useLocation } from "react-router-dom";
import userSlice from "../../store/slices/user";
import { WorkerTaskPermissions } from "../../utils/userUtils";

const ItemBlock = ({ currentItem, startQuantity, handleQuantity }: any) => {
    const currentUser = useAppSelector(userSlice.selectCurrentUser);
    const [quantity, setQuantity] = useState<number>(startQuantity);

    useEffect(() => {
        setQuantity(startQuantity);
    }, [startQuantity]);

    const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
        "& .MuiBadge-badge": {
            right: -20,
            top: 20,
            padding: "0 4px",
        },
    }));

    return (
        <Card
            sx={{
                display: "flex",
                m: 2,
                backgroundColor: "background.default",
            }}
            key={currentItem?.id}
        >
            <Box
                sx={{ display: "flex", flexDirection: "column" }}
                color={"secondary"}
            >
                <CardContent sx={{ pb: 3 }}>
                    <Typography variant="h6">
                        {currentItem?.name || "Item name Not Valid"}
                    </Typography>
                    <Typography variant="body2">
                        {currentItem?.name_si || "Item name Not Valid"}
                    </Typography>
                    <Typography variant="caption">
                        Bulk Price LKR {currentItem?.price}
                    </Typography>
                </CardContent>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        pl: 2,
                        pb: 2,
                        pr: 2,
                    }}
                >
                    <TextField
                        focused
                        size="small"
                        label="Quantity"
                        variant="outlined"
                        type="number"
                        color="info"
                        value={quantity?.toString()}
                        disabled={
                            !currentUser?.permission_array?.includes(
                                WorkerTaskPermissions.ORDER_QUANTITY_CHANGE
                            )
                        }
                        onChange={(event) => {
                            if (+event.target.value < 0) {
                                setQuantity(0);
                                handleQuantity(currentItem, 0);
                            } else {
                                setQuantity(+event.target.value);
                                handleQuantity(
                                    currentItem,
                                    +event.target.value
                                );
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    {currentItem?.unit}
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Typography variant="caption">
                        Sub total {Math.round(currentItem?.price * quantity)}
                    </Typography>
                </Box>
            </Box>
            <StyledBadge
                badgeContent={""}
                color={
                    currentItem?.available === ItemState.InStock
                        ? "success"
                        : "error"
                }
            ></StyledBadge>
            <CardMedia
                component="img"
                sx={{ width: 151 }}
                image={currentItem?.image}
                alt="Item image"
            />
        </Card>
    );
};

export function BasicTable({ rows }: any) {
    return (
        <TableContainer component={Paper} sx={{ maxHeight: 300 }}>
            <Table
                sx={{ minWidth: 50, maxHeight: 50 }}
                aria-label="simple table"
                size="small"
            >
                <TableHead>
                    <TableRow>
                        <TableCell>Item</TableCell>
                        <TableCell align="right">Quantity</TableCell>
                        <TableCell align="right">Unit Price (LKR)</TableCell>
                        <TableCell align="right">Sub total (LKR)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row: any) => (
                        <TableRow
                            key={row?.item?.id}
                            sx={{
                                "&:last-child td, &:last-child th": {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell component="th" scope="row">
                                {row?.item?.name}
                            </TableCell>
                            <TableCell component="th" align="right">
                                {row?.cartQuantity + " " + row?.item?.unit}
                            </TableCell>
                            <TableCell component="th" align="right">
                                {row?.item?.price}
                            </TableCell>
                            <TableCell component="th" align="right">
                                {Math.round(
                                    row?.item?.price * row?.cartQuantity
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const WorkerUpdateOrder = () => {
    const { state } = useLocation();
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(userSlice.selectCurrentUser);

    const [currentOrder, setCurrentOrder] = useState<any>();
    const [masterItemsArray, setMasterItemsArray] = useState<any>([]);

    const [itemsArray, setItemsArray] = useState<any>([]);
    const [selectedVendor, setSelectedVendor] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isAlphabaticalSorted, setIsAlphabaticalSorted] =
        useState<boolean>(true);
    const [cartItems, setCartItems] = useState<any[]>([]);
    // Order Items to DB
    const [finalOrder, setFinalOrder] = useState<any[]>([]);
    const [updatePrice, setUpdatedPrice] = useState<number>(0);
    const [isConfirmeDialog, setIsConfirmeDialog] = useState<boolean>(false);
    const [isOrderPlaced, setIsOrderPlaced] = useState<boolean>(false);

    useEffect(() => {
        window.sessionStorage.setItem("market", "0");
        window.sessionStorage.setItem("cart", "0");
        window.sessionStorage.setItem("orders", "0");
        window.sessionStorage.setItem("profile", "0");
        setIsLoading(true);
        loadOrder();
    }, [isOrderPlaced]);

    useEffect(() => {
        if (currentOrder) {
            loadItems();
        }
    }, [currentOrder]);

    useEffect(() => {
        if (masterItemsArray) {
            updateSystemItemsToOrder();
        }
    }, [masterItemsArray, isAlphabaticalSorted]);

    useEffect(() => {
        if (itemsArray) {
            setUICartItems();
        }
    }, [itemsArray]);

    useEffect(() => {
        if (cartItems) {
            getUpdatedTotalPrice();
            setIsLoading(false);
        }
    }, [cartItems]);

    const loadOrder = async () => {
        const result: any = await dispatch(orderSlice.fetchOrder(state));
        const order = result?.payload?.result;
        setCurrentOrder(order);
    };

    const loadItems = async () => {
        const items: any = await dispatch(itemSlice.fetchMasterInventoryItem());
        setMasterItemsArray(items?.payload);
    };

    const updateSystemItemsToOrder = async () => {
        setSelectedVendor(currentOrder?.userData);
        const currentItemArray = [];
        for (const i of masterItemsArray) {
            let itemPrice = i?.unit_price?.bulk_price;
            if (currentOrder?.userData?.is_premium) {
                itemPrice = i?.unit_price?.bulk_premium_price || 0;
            }
            const itemObject = {
                available: i?.status,
                category: i?.category,
                id: i?.id,
                image: i?.item_image_url,
                name: i?.display_name?.en,
                name_si: i?.display_name?.si,
                price: itemPrice,
                quantity: 0,
                unit: i?.unit_size,
            };
            currentItemArray.push(itemObject);
        }

        if (isAlphabaticalSorted) {
            currentItemArray.sort((a, b) => a.name.localeCompare(b.name));
        }
        setItemsArray(currentItemArray);
    };

    function getItemFromOrder(orderItems: any, itemId: string) {
        for (const item of orderItems) {
            if (item?.id === itemId) {
                return item?.quantity;
            }
        }
        return false;
    }

    function setUICartItems() {
        const orderItems = currentOrder?.items;
        const updatedCartArrayOnLoad = [];
        for (const item of itemsArray) {
            const orderQuantity = getItemFromOrder(orderItems, item?.id);
            if (!orderQuantity) {
                continue;
            }
            handleQuantity(item, orderQuantity);
            updatedCartArrayOnLoad.push({ item, cartQuantity: orderQuantity });
        }
        setCartItems(updatedCartArrayOnLoad);
    }

    // Update Order Item Quantities
    function handleQuantity(item: any, value: any): void {
        if (value < 0) return;

        const cartItemsCopy = [...cartItems];
        const updatedItemIndex = cartItemsCopy.findIndex((curr: any) => {
            return curr?.item?.id === item?.id;
        });
        if (updatedItemIndex === -1) {
            setCartItems([...cartItems, { item, cartQuantity: value }]);
        } else {
            const currentItem = cartItemsCopy[updatedItemIndex];
            const updatedItem = {
                item: currentItem.item,
                cartQuantity: +value,
            };
            const updatedCart: any = [];
            for (const currentCartItem of cartItemsCopy) {
                if (currentCartItem.item.id === item.id) {
                    updatedCart.push(updatedItem);
                } else {
                    updatedCart.push(currentCartItem);
                }
            }
            setCartItems(updatedCart);
        }
    }

    async function placeOrder() {
        const finalOrderTemp = [];
        for (const item of cartItems) {
            if (item?.cartQuantity > 0) {
                finalOrderTemp.push(item);
            }
        }
        if (!state) {
            customToast("No Order", "warning");
            return;
        }
        if (finalOrderTemp.length === 0) {
            customToast("Empty Cart", "warning");
            return;
        }

        setFinalOrder(finalOrderTemp);
        setIsConfirmeDialog(true);
    }

    const handleClose = () => {
        setIsConfirmeDialog(false);
    };

    const getUpdatedTotalPrice = () => {
        let totalPrice = 0;
        for (const i of cartItems) {
            const t = i?.cartQuantity * i?.item?.price;
            totalPrice += Math.round(t);
        }
        setUpdatedPrice(totalPrice);
    };
    const handleOrderComplete = async () => {
        setIsLoading(true);
        setIsConfirmeDialog(false);

        const result = await dispatch(
            orderSlice.updateWorkerBulkOrder({
                cart: finalOrder,
                order: currentOrder,
            })
        );
        if (result.payload.isError === true) {
            customToast(
                `Error Occured! ${result.payload?.message?.message}`,
                "error"
            );
            setIsLoading(false);
            return;
        }
        customToast(result.payload?.message?.text, "success");
        setIsOrderPlaced(!isOrderPlaced);
        setIsLoading(false);
    };

    return (
        <Box
            sx={{
                backgroundColor: "background.paper",
                minHeight: "90vh",
                height: "100%",
            }}
            pb={10}
            pt={2}
        >
            <Typography
                textAlign={"center"}
                variant="h5"
                color={"text.primary"}
            >
                Update Order
            </Typography>
            <ConfirmPopup
                isOpen={isConfirmeDialog}
                handleClose={handleClose}
                text={
                    <Grid container>
                        <Grid item xs={12}>
                            <BasicTable rows={finalOrder} />
                        </Grid>
                        <Grid item xs={12} textAlign={"right"} mt={3}>
                            <Typography
                                textAlign={"right"}
                                variant="body1"
                                color={"secondary"}
                            >
                                Total Price: LKR {updatePrice}
                            </Typography>
                        </Grid>
                    </Grid>
                }
                heading={
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography
                                textAlign={"left"}
                                variant="body1"
                                color={"secondary"}
                            >
                                Update Order {state} ?
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                textAlign={"left"}
                                variant="body1"
                                color={"secondary"}
                            >
                                {selectedVendor?.username}
                            </Typography>
                        </Grid>
                    </Grid>
                }
                handleYes={handleOrderComplete}
            />
            {isLoading ? (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        m: 3,
                    }}
                >
                    <CircularProgress color="secondary" />
                </Box>
            ) : (
                <>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            m: 2,
                        }}
                    >
                        <TextField
                            fullWidth
                            value={state || 0}
                            label="Order Id"
                            variant="outlined"
                            color="info"
                        />
                    </Box>

                    {currentOrder !== undefined ? (
                        <>
                            <Grid
                                container
                                sx={{
                                    justifyContent: "center",
                                    p: 2,
                                }}
                                spacing={2}
                            >
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Order Date"
                                        value={currentOrder?.created_at
                                            ?.toDate()
                                            .toDateString()}
                                        disabled
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        label="Display Name"
                                        value={currentOrder?.order_name}
                                        disabled
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        label="Sort ID"
                                        value={currentOrder?.sort_order_id}
                                        disabled
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={
                                            selectedVendor?.is_premium
                                                ? {
                                                      endAdornment: (
                                                          <InputAdornment position="start">
                                                              <VerifiedIcon
                                                                  sx={{
                                                                      color: "#FFD700",
                                                                  }}
                                                              />
                                                          </InputAdornment>
                                                      ),
                                                  }
                                                : {
                                                      endAdornment: (
                                                          <InputAdornment position="start">
                                                              <VerifiedIcon
                                                                  sx={{
                                                                      color: "#1DA1F2",
                                                                  }}
                                                              />
                                                          </InputAdornment>
                                                      ),
                                                  }
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="secondary"
                                        onClick={placeOrder}
                                        disabled={
                                            !currentUser?.permission_array?.includes(
                                                WorkerTaskPermissions.ORDER_QUANTITY_CHANGE
                                            )
                                        }
                                    >
                                        Checkout Order LKR
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    {!currentUser?.permission_array?.includes(
                                        WorkerTaskPermissions.ORDER_QUANTITY_CHANGE
                                    ) && (
                                        <Alert
                                            variant="standard"
                                            severity="warning"
                                        >
                                            No Permission to modify order.
                                        </Alert>
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        textAlign={"left"}
                                        variant="body1"
                                        color={"secondary"}
                                    >
                                        Current Bill Amount:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        textAlign={"right"}
                                        variant="body1"
                                        color={"secondary"}
                                    >
                                        LKR {currentOrder?.total_price}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        textAlign={"left"}
                                        variant="body1"
                                        color={"secondary"}
                                    >
                                        Updated Bill Amount:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        textAlign={"right"}
                                        variant="body1"
                                        color={"secondary"}
                                    >
                                        LKR {updatePrice}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        textAlign={"left"}
                                        variant="body1"
                                        color={"secondary"}
                                    >
                                        Sort Alphabetical:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} textAlign={"right"}>
                                    <Switch
                                        color="secondary"
                                        checked={isAlphabaticalSorted}
                                        onChange={(event) => {
                                            setIsAlphabaticalSorted(
                                                event?.target?.checked
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            {itemsArray?.map((currentItem: any) => {
                                let quantity = 0;
                                for (const item of cartItems) {
                                    if (currentItem?.id === item?.item?.id) {
                                        quantity = item?.cartQuantity;
                                    }
                                }
                                return (
                                    <ItemBlock
                                        key={currentItem?.id}
                                        currentItem={currentItem}
                                        handleQuantity={handleQuantity}
                                        startQuantity={quantity}
                                    />
                                );
                            })}
                        </>
                    ) : (
                        <></>
                    )}
                </>
            )}
        </Box>
    );
};

export default WorkerUpdateOrder;
