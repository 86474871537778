import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import { configCollection } from "..";
import configSlice from "../../../store/slices/config";

let configListener: any = null;
let serviceConfigListener: any = null;
let inventoryConfigListener: any = null;
let adminConfigListener: any = null;

const config = {
    async getOrderConfig(dispatch: any) {
        try {
            const configDocRef = doc(configCollection, "order");
            const inventoryConfigDocRef = doc(configCollection, "inventory");
            const adminConfigDocRef = doc(configCollection, "admin");
            configListener = onSnapshot(
                configDocRef,
                async (document) => {
                    const docData: any = document?.data();
                    await dispatch(configSlice.setConfig(docData));
                },
                (error) => {
                    console.error(error);
                }
            );
            inventoryConfigListener = onSnapshot(
                inventoryConfigDocRef,
                async (document) => {
                    const docData: any = document?.data();
                    await dispatch(configSlice.setInventoryConfig(docData));
                },
                (error) => {
                    console.error(error);
                }
            );
            adminConfigListener = onSnapshot(
                adminConfigDocRef,
                async (document) => {
                    const docData: any = document?.data();
                    await dispatch(configSlice.setAdminConfig(docData));
                },
                (error) => {
                    console.error(error);
                }
            );

            return {
                isError: true,
                message: "Error Occcured !",
            };
        } catch (error) {
            return { isError: true, message: "Error Occcured !" };
        }
    },
    async unsubscribeConfig(dispatch: any) {
        if (configListener) {
            configListener();
            await dispatch(null);
        }
        if (adminConfigListener) {
            adminConfigListener();
            await dispatch(configSlice.setAdminConfig(null));
        }
    },
    async getServiceConfig(dispatch: any) {
        try {
            const configDocRef = doc(configCollection, "service");
            serviceConfigListener = onSnapshot(
                configDocRef,
                async (document) => {
                    const docData: any = document?.data();
                    if (docData) {
                        await dispatch(configSlice.setServiceConfig(docData));
                    }
                },
                (error) => {
                    console.error(error);
                }
            );

            return {
                isError: true,
                message: "Error Occcured !",
            };
        } catch (error) {
            return { isError: true, message: "Error Occcured !" };
        }
    },
    async unsubscribeServiceConfig(dispatch: any) {
        if (serviceConfigListener) {
            serviceConfigListener();
            await dispatch(null);
        }
    },
    async updateIsOrderRestricted(value: boolean) {
        const docRef = doc(configCollection, "order");
        await updateDoc(docRef, { is_ordering_restricted: value });
    },
    async updateRestrictTime(value: boolean) {
        const docRef = doc(configCollection, "order");
        await updateDoc(docRef, { restrict_time_range: value });
    },
    async updateBankDetails(value: any) {
        const docRef = doc(configCollection, "order");
        await updateDoc(docRef, { bank_details: value });
    },
    async updateHelpline(value: any) {
        const docRef = doc(configCollection, "service");
        await updateDoc(docRef, { helpline: value });
    },
};

export default config;
