const selectors = {
    selectIsLoading: (state: any) => {
        return state.config.isLoading;
    },
    selectIsLoadingProvider: (state: any) => {
        return state.config.isLoadingProvider;
    },
    selectVerifyLinkTime: (state: any) => {
        return state.config.verifyLinkTimestamp;
    },
    selectConfig: (state: any) => {
        return state.config.config;
    },
    selectServiceConfig: (state: any) => {
        return state.config.serviceConfig;
    },
    selectInventoryConfig: (state: any) => {
        return state.config.inventoryConfig;
    },
    selectAdminConfig: (state: any) => {
        return state.config.adminConfig;
    },
};

export default selectors;
