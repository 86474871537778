import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import state from "./state";
import reducers from "./reducers";
import item from "../../../provider/firebase/item";
import { Item } from "item";
import { v4 as uuidv4 } from "uuid";
import { ItemCategory, ItemState } from "../../../utils/itemUtils";
import _ from "lodash";

// Async action for saving user data
const asyncThunks = {
    addItem: createAsyncThunk("item/addItem", async (itemData: any) => {
        try {
            const masterItemPayload: Item = {
                id: uuidv4(),
                created_at: new Date(),
                name_search_term: itemData.name_search_term,
                display_name: itemData.display_name,
                description: itemData.description,
                unit_price: itemData.unit_price,
                unit_size: itemData.unit_size,
                item_image_url: "",
                bulk_price_offset: 15,
                bulk_premium_price_offset: 15,
                item_sku: itemData.item_sku,
                grade: 0,
                item_bill_name: itemData.item_bill_name,
                status: ItemState.InStock,
                category: itemData.category || ItemCategory.vegetable,
            };
            const result = await item.addMasterItem({
                masterItemPayload,
                file: itemData?.item_image,
            });
            return { isError: false, message: result };
        } catch (error) {
            return { isError: true, message: error };
        }
    }),
    uploadPrice: createAsyncThunk(
        "item/uploadPrice",
        async (itemPriceData: any) => {
            try {
                const finalUpdateList: any[] = [];
                for (const item of itemPriceData.itemsArray) {
                    const itemSku = item?.item_sku;
                    let itemPrice = item?.unit_price;
                    let itemPriceOffset = item?.bulk_price_offset;
                    let itemPremiumPriceOffset =
                        item?.bulk_premium_price_offset;
                    let itemStockAvailability = item?.status
                        ? ItemState.InStock
                        : ItemState.OutOfStock;
                    for (const price of itemPriceData?.file) {
                        if (price[0] == itemSku) {
                            if (!_.isNumber(price[3])) {
                                // If file has no source price
                                itemStockAvailability = ItemState.OutOfStock;
                                continue;
                            }
                            if (price[3] === 0) {
                                // If file has source price 0
                                itemStockAvailability = ItemState.OutOfStock;
                                continue;
                            }
                            itemStockAvailability = ItemState.InStock;
                            if (_.isNumber(price[4])) {
                                // If file has a bulk offset
                                itemPriceOffset = price[4];
                                itemPrice = {
                                    ...itemPrice,
                                    bulk_price: price[3] + price[4],
                                    source_price: price[3],
                                };
                            } else {
                                // No bulk offset
                                itemPrice = {
                                    ...itemPrice,
                                    bulk_price:
                                        price[3] + item?.bulk_price_offset,
                                    source_price: price[3],
                                };
                            }
                            // For Premium grade Pricing
                            if (_.isNumber(price[5])) {
                                // If file has a bulk offset
                                itemPremiumPriceOffset = price[5];
                                itemPrice = {
                                    ...itemPrice,
                                    bulk_premium_price: price[3] + price[5],
                                    source_price: price[3],
                                };
                            } else {
                                // No bulk offset
                                itemPrice = {
                                    ...itemPrice,
                                    bulk_premium_price:
                                        price[3] +
                                        item?.bulk_premium_price_offset,
                                    source_price: price[3],
                                };
                            }
                        }
                    }
                    finalUpdateList.push({
                        item,
                        itemPrice,
                        itemPriceOffset,
                        itemPremiumPriceOffset,
                        itemStockAvailability,
                    });
                }
                const result = await item.uploadPrice(finalUpdateList);
                return result;
            } catch (error) {
                return { isError: true, message: error };
            }
        }
    ),
    adminAddInventory: createAsyncThunk(
        "item/adminAddInventory",
        async (itemData: any) => {
            try {
                console.log("add Invetory: ", itemData);
                const result = await item.adminAddInventory(itemData);
                return result;
            } catch (error) {
                return { isError: true, message: error };
            }
        }
    ),
    fetchMasterInventoryItem: createAsyncThunk(
        "item/fetchMasterInventoryItem",
        async (payload, { dispatch }) => {
            const result: any = await item.fetchMasterItems();
            dispatch(actions.setMasterItemList(result));
            return result;
        }
    ),
    adminUpdateMasterInventoryItemNewPrice: createAsyncThunk(
        "item/adminUpdateMasterInventoryItemNewPrice",
        async (payload: any) => {
            const docId = payload?.item?.id;
            const newOffset = payload?.newPriceBulkOffset;
            const newPremiumOffset = payload?.newPricePremiumBulkOffset;
            const newUnitPrice = {
                ...payload?.item?.unit_price,
                source_price: payload?.value,
                bulk_price: payload?.value + newOffset,
                bulk_premium_price: payload?.value + newPremiumOffset,
            };
            await item.adminUpdateMasterInventoryItemNewPrice({
                docId,
                newUnitPrice,
                newOffset,
                newPremiumOffset,
            });
        }
    ),
    adminUpdateMasterInventoryItemStockToggle: createAsyncThunk(
        "item/adminUpdateMasterInventoryItemStockToggle",
        async (payload: any) => {
            const docId = payload?.item?.id;
            const isInStock = payload?.isInStock;
            await item.adminUpdateMasterInventoryItemStockToggle({
                docId,
                isInStock,
            });
        }
    ),
    adminUpdateMasterInventoryItemGroup: createAsyncThunk(
        "item/adminUpdateMasterInventoryItemGroup",
        async (payload: any) => {
            const docId = payload?.item?.id;
            const value = payload?.value;
            await item.adminUpdateMasterInventoryItemGroup({
                docId,
                value,
            });
        }
    ),
    fetchInventoryByUserId: createAsyncThunk(
        "item/fetchInventoryByUserId",
        async (userId: any, { dispatch }) => {}
    ),
    getItemDataFromReference: createAsyncThunk(
        "item/getItemDataFromReference",
        async (itemRef: any) => {
            return await item.getItemDataFromReference(itemRef);
        }
    ),
    fetchVendorInventory: createAsyncThunk(
        "order/fetchVendorInventory",
        async (_arg, { getState }) => {
            try {
                const state: any = getState();
                const payload = {
                    user: state.user.currentUser,
                };
                const result = await item.fetchVendorInventory(payload);
                return { isError: false, message: "Successful", result };
            } catch (error) {
                return { isError: true, message: error };
            }
        }
    ),
    setVendorInventory: createAsyncThunk(
        "order/setVendorInventory",
        async (updatedItems: any, { getState }) => {
            try {
                const state: any = getState();
                const payload = {
                    user: state.user.currentUser,
                    items: updatedItems,
                };
                const result = await item.setVendorInventory(payload);
                return { isError: false, message: "Successful", result };
            } catch (error) {
                return { isError: true, message: error };
            }
        }
    ),
    updateItemSKU: createAsyncThunk(
        "order/updateItemSKU",
        async (updatedItem: any) => {
            try {
                const payload = {
                    item: updatedItem?.currentItem,
                    sku: updatedItem?.skuNumber,
                };
                const result = await item.updateItemSKU(payload);
                return { isError: false, message: "Successful", result };
            } catch (error) {
                return { isError: true, message: error };
            }
        }
    ),
    updateBulkOffset: createAsyncThunk(
        "order/updateBulkOffset",
        async (updatedItem: any) => {
            try {
                const originalUnitPrice = updatedItem?.currentItem?.unit_price;
                const newPriceOffset = updatedItem?.newPriceBulkOffset;
                const updatedUnitPrice = {
                    ...originalUnitPrice,
                    bulk_price:
                        originalUnitPrice?.source_price + newPriceOffset,
                };
                const payload = {
                    id: updatedItem?.currentItem?.id,
                    unit_price: updatedUnitPrice,
                    bulk_price_offset: newPriceOffset,
                };
                await item.updateBulkOffset(payload);
            } catch (error) {
                return { isError: true, message: error };
            }
        }
    ),
    updatePremiumBulkOffset: createAsyncThunk(
        "order/updatePremiumBulkOffset",
        async (updatedItem: any) => {
            try {
                const originalUnitPrice = updatedItem?.currentItem?.unit_price;
                const newPricePremiumBulkOffset =
                    updatedItem?.newPricePremiumBulkOffset;
                const updatedUnitPrice = {
                    ...originalUnitPrice,
                    bulk_premium_price:
                        originalUnitPrice?.source_price +
                        newPricePremiumBulkOffset,
                };
                const payload = {
                    id: updatedItem?.currentItem?.id,
                    unit_price: updatedUnitPrice,
                    bulk_premium_price_offset: newPricePremiumBulkOffset,
                };
                await item.updatePremiumBulkOffset(payload);
            } catch (error) {
                return { isError: true, message: error };
            }
        }
    ),

    // Retail buyer functions
    fetchNearestInventoryItem: createAsyncThunk(
        "item/fetchNearestInventoryItem",
        async (location: any, { dispatch, getState }) => {
            if (!(location.latitude && location.longitude)) {
                return;
            }
            const state: any = getState();
            const payload = {
                user: state.user.currentUser,
                location,
            };
            const result: any = await item.fetchNearestInventory(payload);
            console.log("Result: ", result.data);
            return result.data;
        }
    ),
};

const itemSlice = createSlice({
    // Name of the slice
    name: "item",
    // State of the slice
    initialState: state,
    // Reducers of the slice
    reducers: reducers,
});

export const actions = { ...itemSlice.actions, ...asyncThunks };
export default itemSlice.reducer;
