import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import itemSlice from "../../store/slices/item";
import { customToast } from "../public_components/Toast/CustomToast";
import RefreshIcon from "@mui/icons-material/Refresh";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { ItemState } from "../../utils/itemUtils";
import readXlsxFile from "read-excel-file";
import configSlice from "../../store/slices/config";

const ItemBlock = ({
    currentItem,
    startPrice,
    handlePriceChange,
    bulkOffset,
    premiumBulkOffset,
    isInStockFromDB,
    handleStockChange,
    groupArray,
    currentGroup,
    updateCurrentGroup,
}: any) => {
    const dispatch = useAppDispatch();
    const [newPrice, setNewPrice] = useState<number>(startPrice);
    const [newPriceBulkOffset, setNewPriceBulkOffset] =
        useState<number>(bulkOffset);
    const [newPricePremiumBulkOffset, setNewPremiumPriceBulkOffset] =
        useState<number>(premiumBulkOffset);
    const [newCurrentGroup, setNewCurrentGroup] = useState<string>(
        currentGroup ?? ""
    );
    const [isInStock, setIsInStock] = useState<boolean>(isInStockFromDB);
    const [skuNumber, setSkuNumber] = useState<string>(currentItem?.item_sku);
    useEffect(() => {
        if (isInStockFromDB === isInStock) {
            return;
        }
        handleStockChange(currentItem, isInStock);
    }, [isInStock]);
    useEffect(() => {
        if (currentGroup) {
            setNewCurrentGroup(currentGroup);
        }
    }, [currentGroup]);
    return (
        <Card
            sx={{
                display: "flex",
                m: 2,
                backgroundColor: "background.default",
            }}
            key={currentItem?.id}
        >
            <Box
                sx={{ display: "flex", flexDirection: "column" }}
                color={"secondary"}
            >
                <CardContent sx={{ pb: 3 }}>
                    <Typography variant="h6">
                        {currentItem?.display_name?.si || "Item name Not Valid"}
                    </Typography>
                    <Typography variant="body1">
                        Source LKR {currentItem?.unit_price?.source_price}
                    </Typography>
                </CardContent>
                <CardContent sx={{ pb: 3 }}>
                    <Typography variant="h6">
                        Baseline Bulk LKR {currentItem?.unit_price?.bulk_price}
                    </Typography>
                </CardContent>
                <CardContent sx={{ pb: 3 }}>
                    <Typography variant="h6">
                        Premium Bulk LKR{" "}
                        {currentItem?.unit_price?.bulk_premium_price}
                    </Typography>
                </CardContent>
                <CardContent sx={{ pb: 3 }}>
                    <Grid item xs={12} textAlign={"center"}>
                        <TextField
                            focused
                            size="small"
                            label="Item SKU"
                            variant="outlined"
                            color="info"
                            value={skuNumber}
                            onChange={(event) => {
                                setSkuNumber(event.target.value);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={async () => {
                                                await dispatch(
                                                    itemSlice.updateItemSKU({
                                                        currentItem,
                                                        skuNumber,
                                                    })
                                                );
                                            }}
                                        >
                                            <RefreshIcon color="secondary" />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </CardContent>
                <CardContent sx={{ pb: 3 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            Stock
                        </Grid>
                        <Grid item xs={6} textAlign={"center"}>
                            <Switch
                                checked={isInStock}
                                color="secondary"
                                onChange={(event) => {
                                    setIsInStock(event?.target?.checked);
                                }}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        pl: 2,
                        pb: 2,
                        pr: 2,
                    }}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                focused
                                size="small"
                                label="New Source Price"
                                variant="outlined"
                                type="number"
                                color="info"
                                value={newPrice.toString()}
                                onChange={(event) => {
                                    setNewPrice(+event.target.value);
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            LKR
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                focused
                                size="small"
                                label="Offset"
                                variant="outlined"
                                type="number"
                                color="info"
                                value={newPriceBulkOffset?.toString()}
                                onChange={(event) => {
                                    setNewPriceBulkOffset(+event.target.value);
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            LKR+
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={async () => {
                                                    await dispatch(
                                                        itemSlice.updateBulkOffset(
                                                            {
                                                                currentItem,
                                                                newPriceBulkOffset,
                                                            }
                                                        )
                                                    );
                                                }}
                                            >
                                                <RefreshIcon color="secondary" />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                focused
                                size="small"
                                label="Premium Offset"
                                variant="outlined"
                                type="number"
                                color="info"
                                value={newPricePremiumBulkOffset?.toString()}
                                onChange={(event) => {
                                    setNewPremiumPriceBulkOffset(
                                        +event.target.value
                                    );
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            LKR+
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={async () => {
                                                    await dispatch(
                                                        itemSlice.updatePremiumBulkOffset(
                                                            {
                                                                currentItem,
                                                                newPricePremiumBulkOffset,
                                                            }
                                                        )
                                                    );
                                                }}
                                            >
                                                <RefreshIcon color="secondary" />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={9} sm={9}>
                            <FormControl
                                fullWidth
                                variant="outlined"
                                size="small"
                            >
                                <InputLabel id="demo-simple-select-label">
                                    Item Group
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={newCurrentGroup}
                                    label="Age"
                                    onChange={(event) => {
                                        setNewCurrentGroup(event.target.value);
                                    }}
                                >
                                    {groupArray?.map((element: string) => (
                                        <MenuItem key={element} value={element}>
                                            {element}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <IconButton
                                onClick={() => {
                                    if (newCurrentGroup !== "") {
                                        updateCurrentGroup(
                                            currentItem,
                                            newCurrentGroup
                                        );
                                    }
                                }}
                            >
                                <RefreshIcon color="secondary" />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Button
                        fullWidth
                        variant="contained"
                        color="success"
                        onClick={() => {
                            handlePriceChange(
                                newPrice,
                                currentItem,
                                newPriceBulkOffset,
                                newPricePremiumBulkOffset
                            );
                        }}
                        sx={{ mt: 1 }}
                    >
                        Manual block Sync
                    </Button>
                </Box>
            </Box>
            <CardMedia
                component="img"
                sx={{ width: 151 }}
                image={currentItem.item_image_url}
                alt="Item image"
            />
        </Card>
    );
};

const AdminUpdateItemPrice = () => {
    const dispatch = useAppDispatch();
    const config = useAppSelector(configSlice.selectAdminConfig);
    const [file, setFile] = useState<any[]>([]);
    const [itemsArray, setItemsArray] = useState<any>([]);
    const [groupArray, setGroupArray] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        window.sessionStorage.setItem("user_management", "0");
        window.sessionStorage.setItem("master_item", "0");
        window.sessionStorage.setItem("orders", "0");
        window.sessionStorage.setItem("statistics", "0");
        window.sessionStorage.setItem("vendor_inventory", "0");
        window.sessionStorage.setItem("profile", "0");
        window.sessionStorage.setItem("price-update", "1");
    }, []);

    useEffect(
        () => () => {
            const clearConfig = async () => {
                await dispatch(configSlice.clearConfig());
            };
            clearConfig();
        },
        []
    );

    useEffect(() => {
        fetchItems();
        initUploadInput();
        dispatch(configSlice.getConfig());
    }, []);
    useEffect(() => {
        if (config?.source_groups) {
            setGroupArray(config?.source_groups);
        }
    }, [config]);

    const initUploadInput = () => {
        const input: any = window.document.getElementById("input");
        input?.addEventListener("change", () => {
            try {
                readXlsxFile(input?.files[0]).then((rows: any) => {
                    setFile(rows);
                });
            } catch (e) {
                console.error("Error: File Input Reset");
            }
        });
    };

    const uploadPrices = async () => {
        if (file.length > 0) {
            await dispatch(itemSlice.uploadPrice({ itemsArray, file }));
            fetchItems();
        }
    };

    const fetchItems = async () => {
        setIsLoading(true);
        const items: any = await dispatch(itemSlice.fetchMasterInventoryItem());

        const currentItemArray = [];
        for (const i of items.payload) {
            const itemObject = {
                id: i?.id,
                unit_price: i?.unit_price,
                display_name: i?.display_name,
                item_image_url: i?.item_image_url,
                item_sku: i?.item_sku,
                new_price: i?.source_price || 0,
                bulk_price_offset: i?.bulk_price_offset || 0,
                bulk_premium_price_offset: i?.bulk_premium_price_offset || 0,
                status: i?.status === ItemState.InStock ? true : false,
                current_group: i?.current_group,
            };
            currentItemArray.push(itemObject);
        }
        setItemsArray(currentItemArray);
        setIsLoading(false);
    };

    const handleStockChange = async (item: any, isInStock: boolean) => {
        await dispatch(
            itemSlice.adminUpdateMasterInventoryItemStockToggle({
                item,
                isInStock,
            })
        );
    };
    const updateCurrentGroup = async (item: any, value: string) => {
        await dispatch(
            itemSlice.adminUpdateMasterInventoryItemGroup({
                item,
                value,
            })
        );
    };

    const handlePriceChange = async (
        value: any,
        item: any,
        newPriceBulkOffset: number,
        newPricePremiumBulkOffset: number
    ) => {
        if (!value) {
            return;
        }
        if (value == 0) {
            customToast("Price is 0", "info");
            return;
        }
        if (
            value == item?.unit_price?.source_price &&
            newPriceBulkOffset === item?.bulk_price_offset
        ) {
            customToast("No price change!", "info");
            return;
        }

        customToast("Price Updated Successfully!", "success");
        await dispatch(
            itemSlice.adminUpdateMasterInventoryItemNewPrice({
                value,
                item,
                newPriceBulkOffset,
                newPricePremiumBulkOffset,
            })
        );
    };

    return (
        <Box
            sx={{
                backgroundColor: "background.paper",
                minHeight: "90vh",
                height: "100%",
            }}
            pb={10}
            pt={2}
        >
            <Typography
                textAlign={"center"}
                variant="h5"
                color={"text.primary"}
            >
                Update Paliyagoda Item Prices
            </Typography>
            <Grid container mt={1}>
                <Grid item xs={12} textAlign={"center"}>
                    <Button
                        onClick={() => {
                            fetchItems();
                        }}
                        variant="contained"
                        color="secondary"
                        sx={{ borderRadius: 500 }}
                    >
                        <RefreshIcon />
                    </Button>
                </Grid>
                <CardContent sx={{ pb: 3 }}>
                    <Grid item xs={12} textAlign={"center"}>
                        <TextField
                            type="file"
                            id="input"
                            color="info"
                            helperText="PRICE INPUT FILE"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={uploadPrices}>
                                            <UploadFileIcon color="secondary" />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </CardContent>
            </Grid>
            {isLoading ? (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        m: 3,
                    }}
                >
                    <CircularProgress color="secondary" />
                </Box>
            ) : (
                <>
                    {itemsArray.map((currentItem: any) => {
                        return (
                            <ItemBlock
                                key={currentItem?.id}
                                currentItem={currentItem}
                                handlePriceChange={handlePriceChange}
                                startPrice={currentItem?.new_price}
                                premiumBulkOffset={
                                    currentItem?.bulk_premium_price_offset
                                }
                                bulkOffset={currentItem?.bulk_price_offset}
                                isInStockFromDB={currentItem?.status}
                                handleStockChange={handleStockChange}
                                groupArray={groupArray}
                                currentGroup={currentItem?.current_group}
                                updateCurrentGroup={updateCurrentGroup}
                            />
                        );
                    })}
                </>
            )}
        </Box>
    );
};

export default AdminUpdateItemPrice;
