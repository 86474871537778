const reducers = {
    setIsLoading: (state: any, action: { payload: boolean }) => {
        state.isLoading = action.payload;
    },
    setIsLoadingProvider: (state: any, action: { payload: boolean }) => {
        state.isLoadingProvider = action.payload;
    },
    setVerifyLinkTime: (state: any, action: { payload: boolean }) => {
        state.verifyLinkTimestamp = action.payload;
    },
    setConfig: (state: any, action: { payload: boolean }) => {
        state.config = action.payload;
    },
    setServiceConfig: (state: any, action: { payload: boolean }) => {
        state.serviceConfig = action.payload;
    },
    setInventoryConfig: (state: any, action: { payload: boolean }) => {
        state.inventoryConfig = action.payload;
    },
    setAdminConfig: (state: any, action: { payload: any }) => {
        state.adminConfig = action.payload;
    },
    clearVerifyLinkTime: (state: any) => {
        state.verifyLinkTimestamp = null;
    },
};

export default reducers;
