import {
    doc,
    onSnapshot,
    setDoc,
    Unsubscribe,
    updateDoc,
} from "firebase/firestore";
import {
    crateCollection,
    functions,
    masterCrateCollection,
    ref,
    storage,
    userCollection,
} from "..";
import { getDownloadURL, uploadBytes } from "firebase/storage";
import { Dimensions, Prefix } from "inventory-item";
import crateSlice from "../../../store/slices/crate";
import { Crate } from "../../../utils/crateUtils";
import { httpsCallable } from "firebase/functions";
import { customToast } from "../../../components/public_components/Toast/CustomToast";

interface CratePayload {
    file: File[];
    vendor: Prefix;
    size: Prefix;
    color: Prefix;
    model: string;
    crate_prefix: string;
    crate_weight: number;
    crate_weight_capacity: number;
    crate_space_capacity: number;
    stack_size: number;
    is_nestable: boolean;
    outer_dimensions: Dimensions;
    inner_dimensions: Dimensions;
}
let crateListener: Unsubscribe;
let crateStatListener: Unsubscribe;

const crate = {
    // Use this only for admin User
    async addMasterCrate(payload: CratePayload) {
        console.log(payload.file[0]);

        const masterCrateRef = doc(masterCrateCollection);
        let imageUrlBucket = "";

        const imageUrl = URL.createObjectURL(payload?.file[0]);
        if (imageUrl) {
            let blob = await fetch(imageUrl).then((r) => r.blob());
            const storageRef = ref(
                storage,
                `crate/images/${masterCrateRef.id}.jpg`
            );
            await uploadBytes(storageRef, blob);
            // Get the download URL
            await getDownloadURL(storageRef)
                .then((url) => {
                    imageUrlBucket = url;
                })
                .catch((error) => {
                    switch (error.code) {
                        case "storage/object-not-found":
                            console.error("No file Found");
                            break;
                        case "storage/unauthorized":
                            console.error("No permission to upload file");
                            break;
                        case "storage/canceled":
                            console.error("File Upload canceled");
                            break;
                        case "storage/unknown":
                            console.error("Bucket not found");
                            break;
                    }
                });

            console.log(payload, imageUrlBucket);
            const final = {
                id: masterCrateRef.id,
                image: imageUrlBucket,
                vendor: payload.vendor,
                size: payload.size,
                color: payload.color,
                model: payload.model,
                crate_prefix: payload.crate_prefix,
                crate_weight: payload.crate_weight,
                crate_weight_capacity: payload.crate_weight_capacity,
                crate_space_capacity: payload.crate_space_capacity,
                stack_size: payload.stack_size,
                is_nestable: payload.is_nestable,
                outer_dimensions: payload.outer_dimensions,
                inner_dimensions: payload.inner_dimensions,
            };
            await setDoc(masterCrateRef, final);
        }
    },
    async subscribeCrateList(dispatch: any) {
        if (crateListener) {
            crateListener();
        }
        crateListener = onSnapshot(masterCrateCollection, async (snapshot) => {
            const c: any = snapshot.docs.map((document) => document.data());
            await dispatch(crateSlice.setMasterCrateList(c));
        });
    },
    async addCrate(payload: Crate) {
        const crateMasterDocumentRef = doc(masterCrateCollection, payload?.id);
        const crateDocumentRef = doc(crateCollection, payload?.crate_QR_Code);

        // Master Crate Reference is added Here
        const payloadData: Crate = {
            ...payload,
            crate_last_user: {
                user_ref: doc(
                    userCollection,
                    payload.crate_last_user.user_data?.id
                ),
                user_data: payload.crate_last_user.user_data,
            },
            master_crate_doc_reference: crateMasterDocumentRef,
        };
        await setDoc(crateDocumentRef, payloadData);
    },
    async tagOrderCrate(payload: Crate) {
        try {
            const tagOrderCrate = httpsCallable(functions, "tagOrderCrate");
            const result = await tagOrderCrate(payload);
            return result?.data;
        } catch (error) {
            return { isError: true, message: "Error Occured!" };
        }
    },
    async tagCrate(payload: Crate) {
        try {
            const tagCrate = httpsCallable(functions, "tagCrate");
            const result = await tagCrate(payload);
            return result?.data;
        } catch (error) {
            return { isError: true, message: "Error Occured!" };
        }
    },
    async fetchOrderCrate(payload: Crate) {
        try {
            const fetchOrderCrate = httpsCallable(functions, "fetchOrderCrate");
            const result = await fetchOrderCrate(payload);
            return result?.data;
        } catch (error) {
            return { isError: true, message: "Error Occured!" };
        }
    },
    async fetchCrateList() {
        try {
            const fetchCrateList = httpsCallable(functions, "fetchCrateList");
            const result = await fetchCrateList();
            return result?.data;
        } catch (error) {
            return { isError: true, message: "Error Occured!" };
        }
    },
    async updateCrateDeliveryState(payload: any) {
        try {
            const crateDocRef = doc(crateCollection, payload?.crateNumber);
            await updateDoc(crateDocRef, { is_out: payload?.value });
        } catch (error) {
            throw new Error("Update Failed!");
        }
    },
    async subscribeToCrateCollection(dispatch: any) {
        try {
            if (crateStatListener) {
                crateStatListener();
            }
            crateStatListener = onSnapshot(crateCollection, async () => {
                try {
                    const fetchCrateStatistics = httpsCallable(
                        functions,
                        "fetchCrateStatistics"
                    );
                    const result: any = await fetchCrateStatistics();
                    await dispatch(
                        crateSlice.setCrateStatistics(result?.data?.result)
                    );
                } catch (error) {
                    customToast("Error Occured", "error");
                    return { isError: true, message: "Error Occured!" };
                }
            });
            return { isError: false, message: "Subscribed!" };
        } catch (error) {
            return { isError: true, message: "Error Occured!" };
        }
    },
};

export default crate;
