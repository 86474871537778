import { useEffect, useState } from "react";
import {
    Box,
    Typography,
    Checkbox,
    FormControlLabel,
    Paper,
    Card,
    Grid,
    CircularProgress,
    LinearProgress,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../../hooks";
import crateSlice from "../../store/slices/crate";

const CrateListItem = ({ element }: any) => {
    const dispatch = useAppDispatch();
    const [isChecked, setIsChecked] = useState(false);
    const [currentCrate] = useState(element?.id);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (element?.is_out !== null) {
            setIsChecked(element?.is_out);
        }
        setIsLoading(false);
    }, [element]);

    const handleCheckboxChange = (crateNumber: any) => (event: any) => {
        try {
            if (event.target.checked) {
                updateCrateState(crateNumber, true);
            } else {
                updateCrateState(crateNumber, false);
            }
        } catch (error) {
            console.error("Update Failed!");
        }
    };

    const updateCrateState = async (crateNumber: any, value: boolean) => {
        setIsLoading(true);
        const result: any = await dispatch(
            crateSlice.updateCrateDeliveryState({ crateNumber, value })
        );

        if (!result.payload?.isError) {
            setIsChecked(value);
        }
        setIsLoading(false);
    };

    return (
        <Grid container item xs={12} key={element.id} alignItems="center">
            <Grid
                item
                xs={3}
                container
                justifyContent="center"
                alignItems="center"
            >
                <img
                    height={40}
                    src={element?.image}
                    alt=""
                    style={{ display: "block" }}
                />
            </Grid>
            <Grid
                item
                xs={9}
                container
                justifyContent="left"
                alignItems="center"
            >
                {!isLoading ? (
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="error"
                                checked={isChecked}
                                onChange={handleCheckboxChange(currentCrate)}
                                name={`crate-${element?.crate_number}`}
                            />
                        }
                        label={`Crate- ${element?.crate_number}`}
                    />
                ) : (
                    <CircularProgress size={20} color="warning" />
                )}
            </Grid>
        </Grid>
    );
};

const OrderCrateDetailsView = () => {
    const { state } = useLocation();
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [crateArray, setCrateArray] = useState([]);
    const [crateGroupArray, setCrateGroupArray] = useState([]);

    useEffect(() => {
        const fetchItem = async () => {
            setIsLoading(true);
            const result: any = await dispatch(
                crateSlice.fetchOrderCrate(state?.orderId)
            );
            setCrateArray(result?.payload?.result?.crateDataArray);
            setCrateGroupArray(result?.payload?.result?.sortedArray);
            setIsLoading(false);
        };
        fetchItem();
    }, [state]);

    return (
        <Box
            sx={{
                p: 3,
                backgroundColor: "background.paper",
                minHeight: "90vh",
                marginBottom: 6,
            }}
        >
            {isLoading ? (
                <Card
                    sx={{
                        backgroundColor: "background.default",
                        boxShadow: 4,
                        borderRadius: 0,
                        padding: 3,
                    }}
                >
                    <Grid container alignItems={"center"}>
                        <Grid item xs={12} textAlign={"center"}>
                            <LinearProgress color="secondary" />
                        </Grid>
                    </Grid>
                </Card>
            ) : (
                <>
                    <Card
                        sx={{
                            backgroundColor: "background.default",
                            boxShadow: 4,
                            borderRadius: 0,
                            padding: 3,
                        }}
                    >
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="h5" gutterBottom>
                                    Order Details
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="caption">
                                    Order Delivery Date: {state?.deliveryDate}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="caption">
                                    Order ID: {state?.orderId}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="caption" gutterBottom>
                                    Order Name: {state?.orderName}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                    <Card
                        sx={{
                            backgroundColor: "background.default",
                            boxShadow: 4,
                            borderRadius: 0,
                            padding: 3,
                        }}
                    >
                        <Grid container item xs={12} alignItems="left">
                            {crateGroupArray.map((element: any) => {
                                if (!element) {
                                    return;
                                }
                                if (!(element?.items.length > 0)) {
                                    return;
                                }
                                return (
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        key={element?.id}
                                        p={1}
                                        alignContent={"center"}
                                    >
                                        <Grid item xs={4}>
                                            <img
                                                height={40}
                                                src={element?.image}
                                                alt=""
                                                style={{ display: "block" }}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body2">
                                                {element?.size?.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="body2">
                                                {element?.color?.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography
                                                variant="body2"
                                                align={"right"}
                                            >
                                                {element?.items.length}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Card>

                    <Paper elevation={1} sx={{ p: 2 }}>
                        <Box
                            sx={{
                                mt: 2,
                                p: 2,
                                border: "1px solid #ccc",
                                borderRadius: 2,
                            }}
                        >
                            <Typography variant="caption" gutterBottom>
                                Checkbox Key:
                            </Typography>
                            <FormControlLabel
                                control={<Checkbox checked={false} />}
                                label={
                                    <Typography variant="caption">
                                        Crate collected by FarmLink
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={true} color="error" />
                                }
                                label={
                                    <Typography variant="caption">
                                        Crate dropped at vendor
                                    </Typography>
                                }
                            />
                        </Box>
                    </Paper>
                    <Typography
                        variant="h5"
                        color={"secondary"}
                        sx={{ mt: 4, mb: 2 }}
                    >
                        Crate List
                    </Typography>
                    <Paper elevation={1} sx={{ p: 2 }}>
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                        >
                            {crateArray.map((element: any) =>
                                element ? (
                                    <CrateListItem
                                        key={element?.id}
                                        element={element}
                                    />
                                ) : (
                                    <></>
                                )
                            )}
                        </Grid>
                    </Paper>
                </>
            )}
        </Box>
    );
};

export default OrderCrateDetailsView;
