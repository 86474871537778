import { useEffect, useState } from "react";
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import { useAppDispatch } from "../../hooks";
import crateSlice from "../../store/slices/crate";

const StatisticsCrateList = () => {
    const dispatch = useAppDispatch();

    const [crateArray, setCrateArray] = useState<any>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");

    useEffect(() => {
        window.sessionStorage.setItem("user_management", "0");
        window.sessionStorage.setItem("master_item", "1");
        window.sessionStorage.setItem("orders", "0");
        window.sessionStorage.setItem("statistics", "0");
        window.sessionStorage.setItem("vendor_inventory", "0");
        window.sessionStorage.setItem("profile", "0");
        window.sessionStorage.setItem("price-update", "0");
        fetchCrateList();
    }, []);

    const fetchCrateList = async () => {
        const result = await dispatch(crateSlice.fetchCrateList());
        setCrateArray(result.payload);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const filteredCrateArray = crateArray.filter(
        (crate: any) =>
            crate?.code?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            crate?.crate_at?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Box
            sx={{
                pt: 3,
                backgroundColor: "background.paper",
                minHeight: "90vh",
                height: "100%",
                pb: 10,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 2,
                    padding: 2,
                }}
            >
                <TextField
                    label="Search by Code or Location"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearch}
                    sx={{ width: "100%", maxWidth: 500, mb: 2 }}
                />
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Code</TableCell>
                                <TableCell align="right">Color</TableCell>
                                <TableCell align="right">Size</TableCell>
                                <TableCell align="right">Location</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredCrateArray.map((row: any) => (
                                <TableRow
                                    key={row.id}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.code}
                                    </TableCell>
                                    <TableCell align="right">
                                        {row.color}
                                    </TableCell>
                                    <TableCell align="right">
                                        {row.size}
                                    </TableCell>
                                    <TableCell align="right">
                                        {row.crate_at}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default StatisticsCrateList;
