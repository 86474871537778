export enum UserType {
    Vendor = "vendor",
    Worker = "worker",
    Driver = "driver",
    Admin = "admin",
    RetailBuyer = "retail-buyer",
    none = "none",
    BulkBuyer = "bulk-buyer",
}

export enum WorkerTaskPermissions {
    ORDER_QUANTITY_CHANGE = "ORDER_QUANTITY_CHANGE",
    ORDER_PRICE_CHANGE = "ORDER_PRICE_CHANGE",
    ORDER_ITEM_READY = "ORDER_ITEM_READY",
    SEND_CRATE = "SEND_CRATE",
    RECIEVE_CRATE = "RECIEVE_CRATE",
    ORDER_TAG_CRATE = "ORDER_TAG_CRATE",
    ORDER_GENERATE_INVOICE = "ORDER_GENERATE_INVOICE",
    PLACE_ORDER = "PLACE_ORDER",
}
