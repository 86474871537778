import {
    Box,
    Button,
    Grid,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import "./NotFound.css";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useEffect, useState } from "react";
import crateSlice from "../../store/slices/crate";
import { pieArcLabelClasses, PieChart, useDrawingArea } from "@mui/x-charts";
import { useNavigate } from "react-router-dom";

const size = {
    height: 200,
};

const StyledText = styled("text")(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: "middle",
    dominantBaseline: "central",
    fontSize: 20,
}));

function PieCenterLabel({ children }: Readonly<{ children: React.ReactNode }>) {
    const { width, height, left, top } = useDrawingArea();
    return (
        <StyledText x={left + width / 2} y={top + height / 2}>
            {children}
        </StyledText>
    );
}

const Statistics = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const crateStatistics = useAppSelector(crateSlice.selectCrateStatistics);
    const [crateLocationsStatisticsData, setCrateLocationsStatisticsData] =
        useState<any>([]);
    const [crateTypeStatisticsData, setCrateTypeStatisticsData] = useState<any>(
        []
    );
    const [totalCrates, setTotalCrates] = useState<number>(0);

    useEffect(() => {
        window.sessionStorage.setItem("user_management", "0");
        window.sessionStorage.setItem("orders", "0");
        window.sessionStorage.setItem("statistics", "1");
        window.sessionStorage.setItem("master_item", "0");
        window.sessionStorage.setItem("vendor_inventory", "0");
        window.sessionStorage.setItem("profile", "0");
        window.sessionStorage.setItem("price-update", "0");
        subscribeToCrateStatistics();
    }, []);
    useEffect(() => {
        mapLocationCount(crateStatistics);
    }, [crateStatistics]);

    const subscribeToCrateStatistics = async () => {
        await dispatch(crateSlice.subscribeToCrateCollection());
    };
    const openCrateList = () => {
        navigate(`crate-list`);
    };

    const mapLocationCount = (payload: any) => {
        if (!payload?.crateMapped) {
            return;
        }
        const { crateMapped } = payload;
        const piechartLocationData = [
            {
                id: 0,
                value: payload?.cratesAtFL,
                label: `${payload?.cratesAtFL} FarmLink`,
            },
            {
                id: 2,
                value: payload?.lowcountryCrates,
                label: `${payload?.lowcountryCrates} LOW`,
            },
            {
                id: 3,
                value: payload?.upcountryCrates,
                label: `${payload?.upcountryCrates} UP`,
            },

            {
                id: 1,
                value: payload?.cratesOutAtVendor,
                label: `${payload?.cratesOutAtVendor} Vendors`,
            },
        ];

        setCrateLocationsStatisticsData(piechartLocationData);
        setTotalCrates(payload?.cratesRegisteredTotal);
        const piechartTypeData = [];
        for (const cratetype of crateMapped) {
            piechartTypeData.push({
                id: cratetype?.id,
                value: cratetype?.crates?.length,
                label: cratetype?.type_data.crate_prefix,
            });
        }
        setCrateTypeStatisticsData(piechartTypeData);
    };

    return (
        <Box
            sx={{
                backgroundColor: "background.paper",
                minHeight: "90vh",
                height: "100%",
                paddingBottom: 10,
            }}
        >
            <Grid container>
                <Grid item xs={12}>
                    <Typography
                        textAlign={"center"}
                        variant="h6"
                        color={"secondary"}
                    >
                        Crate Location Distribution
                    </Typography>
                </Grid>
                <Grid item xs={12} textAlign={"center"}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={openCrateList}
                    >
                        Crate List
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <PieChart
                        series={[
                            {
                                data: crateLocationsStatisticsData,
                                innerRadius: 60,
                                highlightScope: {
                                    faded: "global",
                                    highlighted: "item",
                                },
                                faded: {
                                    innerRadius: 60,
                                    additionalRadius: -5,
                                    color: "gray",
                                },
                            },
                        ]}
                        {...size}
                    >
                        <PieCenterLabel>Total {totalCrates}</PieCenterLabel>
                    </PieChart>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        textAlign={"center"}
                        variant="h6"
                        color={"secondary"}
                    >
                        Crate Type Distribution
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <PieChart
                        series={[
                            {
                                // data: crateTypeStatisticsData,
                                innerRadius: 60,
                                highlightScope: {
                                    faded: "global",
                                    highlighted: "item",
                                },
                                faded: {
                                    innerRadius: 60,
                                    additionalRadius: -5,
                                    color: "gray",
                                },

                                arcLabel: (item) =>
                                    `${item.label} (${item.value})`,
                                arcLabelMinAngle: 30,
                                data: crateTypeStatisticsData,
                            },
                        ]}
                        sx={{
                            [`& .${pieArcLabelClasses.root}`]: {
                                fill: "white",
                                fontWeight: "bold",
                                fontSize: 8,
                            },
                        }}
                        {...size}
                        slotProps={{
                            legend: { hidden: true },
                        }}
                    >
                        <PieCenterLabel>Total {totalCrates}</PieCenterLabel>
                    </PieChart>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        textAlign={"center"}
                        variant="h6"
                        color={"secondary"}
                    >
                        Crate Type-Location Distribution
                    </Typography>
                </Grid>
                <Grid container item xs={12}>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table aria-label="crate location table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Size</TableCell>
                                        <TableCell align="right">
                                            Up Country
                                        </TableCell>
                                        <TableCell align="right">
                                            Low Country
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow
                                        sx={{
                                            "&:last-child td, &:last-child th":
                                                { border: 0 },
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            Small
                                        </TableCell>
                                        <TableCell align="right">
                                            {crateStatistics?.cratesUpSm}
                                        </TableCell>
                                        <TableCell align="right">
                                            {crateStatistics?.cratesLwSm}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow
                                        sx={{
                                            "&:last-child td, &:last-child th":
                                                { border: 0 },
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            Medium
                                        </TableCell>
                                        <TableCell align="right">
                                            {crateStatistics?.cratesUpMd}
                                        </TableCell>
                                        <TableCell align="right">
                                            {crateStatistics?.cratesLwMd}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow
                                        sx={{
                                            "&:last-child td, &:last-child th":
                                                { border: 0 },
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            Large
                                        </TableCell>
                                        <TableCell align="right">
                                            {crateStatistics?.cratesUpLg}
                                        </TableCell>
                                        <TableCell align="right">
                                            {crateStatistics?.cratesLwLg}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow
                                        sx={{
                                            "&:last-child td, &:last-child th":
                                                { border: 0 },
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            Jumbo
                                        </TableCell>
                                        <TableCell align="right">
                                            {crateStatistics?.cratesUpJm}
                                        </TableCell>
                                        <TableCell align="right">
                                            {crateStatistics?.cratesLwJm}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Statistics;
