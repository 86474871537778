import { DocumentReference, Timestamp } from "firebase/firestore";

export function crateCodeToPrefixNumber(crateCode: string) {
    const crateCodeSplit = crateCode.split("-");
    const prefix = crateCodeSplit[0];
    const number = crateCodeSplit[1];
    return { prefix, number };
}

export interface Crate {
    // Crate internal and external Identifiers
    master_crate_doc_reference: DocumentReference | null;
    id: string;
    created_at: Date;
    crate_QR_Code: string;
    crate_number: string;
    crate_prefix: string;
    // Crate Qualitative Parameters
    image: string;
    max_weight_capacity: number;
    crate_size: { name: string; prefix: string };
    crate_color: { name: string; prefix: string };
    // Operational Properties
    crate_last_user: CrateUser; // Current User the crate is currently with
    current_item_in_crate: CrateItem; // Current Item in the Crate
    is_crate_has_item: boolean; // Is crate is containing an item
    current_crate_order: CrateOrder; // Current Order The Crate is catering to
    is_out: boolean; // Out of CDC
}

export interface CrateUser {
    user_ref: DocumentReference | null;
    user_data: any;
}
export interface CrateItem {
    item_ref: DocumentReference | null;
    item_data: any;
    quantity: number;
}
export interface CrateOrder {
    ref: DocumentReference | null;
    id: string;
    to: string;
}
export interface CrateLog {
    date: Timestamp;
    crate_user: { user_ref: DocumentReference | null; user_data: any };
    item_in_crate: {
        item_ref: DocumentReference | null;
        item_data: any;
        quantity: number;
    };
    crate_order: CrateOrder | null;
    custom_notes: string;
}
